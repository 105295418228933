import sanityClient from "@sanity/client"
// import clientConfig from "../../client-config"

export function cn(...args) {
  return args.filter(Boolean).join(" ")
}

export function buildImageObj(source = { asset: {} }) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export function toPlainText(blocks) {
  if (!blocks) {
    return ""
  }
  return blocks
    .map(block => {
      if (block._type !== "block" || !block.children) {
        return ""
      }
      return block.children.map(child => child.text).join("")
    })
    .join("\n\n")
}

export const getMousePos = e => {
  let posx = 0
  let posy = 0
  if (!e) e = window.event
  if (e.pageX || e.pageY) {
    posx = e.pageX
    posy = e.pageY
  } else if (e.clientX || e.clientY) {
    // posx = e.clientX + body.scrollLeft + document.documentElement.scrollLeft
    // posy = e.clientY + body.scrollTop + document.documentElement.scrollTop
    posx = e.clientX + document.documentElement.scrollLeft
    posy = e.clientY + document.documentElement.scrollTop
  }

  return { x: posx, y: posy }
}

export const isTablet = () => {
  return window.matchMedia("(max-width: 768px)").matches
}

export const client = sanityClient({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: "production",
  useCdn: false,
})
